var MantleCarousel = MantleCarousel || {};
var MantleWindow = MantleWindow || {};
var prodcat = prodcat || {};
var site = site || {};

(function ($) {
  function _setSmooshColor(skuId, $product) {
    var $smoosh = $('.js-product-smoosh', $product);
    var sku;

    if (skuId) {
      sku = prodcat.data.getSku(skuId);
      if (sku && sku.HEX_VALUE_STRING) {
        $smoosh.css('background-color', sku.HEX_VALUE_STRING);
      }
    }
  }

  $(document).on('click', '.product-shade-picker__shade_family', function () {
    var $menu = $(this);
    var $product = $(this).closest('.js-product');
    var $menu_links = $('.product-shade-picker__shade_family', $product);

    $menu_links.removeClass('active');
    $menu.addClass('active');

    var $slider = $('.product-shade-picker__shades', $product);

    // Unhide shades.
    if (MantleCarousel.isCarousel($slider)) {
      $slider.slick('slickUnfilter');
    }

    var shade_family = $menu.data('shade-family');
    var $shades = $('.js-product-shade', $product);

    $shades.each(function (i, obj) {
      var $shade = $(obj);
      var _family = $shade.data('shade-family');
      var $shade_item = $shade.parent(); // shades have a wrapper

      if (shade_family === 'all') {
        $shade_item.removeClass('shade-hidden');

        return;
      }
      if (_family && _.includes(_family.split(','), shade_family)) {
        $shade_item.removeClass('shade-hidden');

        return;
      }
      // process hidden shades
      $shade_item.addClass('shade-hidden');
    });

    var $active_shade = $('.js-product-shade.active', $product);

    if ($active_shade.length === 0) {
      return;
    }

    $menu.trigger('shadePicker:visible-shade-change');

    if (MantleCarousel.isCarousel($slider)) {
      $slider.slick('slickFilter', ':not(.shade-hidden)');
    }

    // if active shade is in new shade family. don't try to switch to it.
    if ($active_shade.closest('.js-shade-picker-item:not(.shade-hidden)', $product).length === 1) {
      return;
    }

    // switch to the first shade if the old active shade is no longer visible
    $('.js-shade-picker-item:not(.shade-hidden)', $product)
      .first()
      .find('.js-product-shade')
      .trigger('click');
  });

  $(document).on('click', '.js-product-shade', function (event) {
    var $shade = $(this);
    var skuId = $shade.data('sku');
    var productDelay = 50;

    if (!skuId) {
      return;
    }

    var sku = prodcat.data.getSku(skuId);

    if (!sku || $shade.hasClass('active')) {
      return;
    }

    var skuBaseId = sku.SKU_BASE_ID;
    var $product = $shade.closest('.js-product');
    var $shadeLabel = $('.js-product-shade-picker-label', $product);

    // Toggle the active state of the selected shades
    $shade.closest('.js-product-shade-picker').find('.js-product-shade').removeClass('active');
    $shade.addClass('active');
    $shadeLabel.animate({ opacity: 0 }, 0, 'linear', function () {
      $shadeLabel.text($shade.attr('title'));
      $shadeLabel.animate({ opacity: 1 }, 750, 'linear');
    });

    setTimeout(() => {
      $product.trigger('product.skuSelect', [skuBaseId]);
    }, productDelay);
  });

  /*
   * Triggers logic to make sure the selected sku is viewable in the active
   * shade family. This can happen from selecting a shade from dropdown.
   *
   * If the actie shade is not in the active shade family, we defualt to all
   */
  $(document).on('shadePicker:ensureActiveFamily', function (event, $product) {
    var $all_link = $('.product-shade-picker__shade_family[data-shade-family="all"]', $product);
    var $active_shade = $('.js-product-shade.active', $product);

    if ($active_shade.closest('.js-shade-picker-item:not(.shade-hidden)', $product).length == 1) {
      // our active shade isn't hidden. shade_family is valid.
      return;
    }
    // fake a click on all
    $all_link.trigger('click');
  });

  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    var $product = $(this);

    $product.triggerHandler('shadePicker:selectShade', [skuBaseId]);
  });

  $('.js-product-shade').on('mouseenter', function () {
    var $shade = $(this);
    var $product = $shade.closest('.js-product');
    var skuBaseId = $shade.data('sku');

    $shade.trigger('shadePickerShade:hover-in', [skuBaseId]);
    _setSmooshColor(skuBaseId, $product);
  });

  $(document).on('mouseleave', '.js-product-shade', function () {
    var $shade = $(this);
    var $product = $shade.closest('.js-product');
    var skuBaseId = $shade.data('sku');

    $shade.trigger('shadePickerShade:hover-out', [skuBaseId]);
    _setSmooshColor(skuBaseId, $product);
  });

  $(document).on('shadePicker:visible-shade-change', '.js-product-shade-picker', function () {
    var $shadepicker = $(this);
    var $product = $shadepicker.closest('.js-product');
    var $visible_shades = $('.js-product-shade:visible', $shadepicker);
    var $all_shade_items = $('.js-shade-picker-item', $shadepicker);
    var visible_shade_count = $visible_shades.length;

    $shadepicker.removeClass('has-missing-shades');

    var product_id = $product.data('product-id');
    var prodData = prodcat.data.getProduct(product_id);

    if (!prodData.skus) {
      return;
    }

    var total_skus = prodData.skus.length;
    var missing_skus = total_skus - visible_shade_count;

    if (missing_skus > 0) {
      $shadepicker.addClass('has-missing-shades');
    }

    // if this class is set. that means that all shades are visible. so ntohing
    // to calculate. we use visible so css can be what defines the visiblity
    if (!$shadepicker.hasClass('show-shade-overflow')) {
      var not_css_visiblity_hidden = 0;

      // note :visible only works with display none
      $all_shade_items.each(function (i, obj) {
        var $shade_item = $(obj);

        if ($shade_item.css('visibility') !== 'hidden') {
          not_css_visiblity_hidden++;
        }
      });
      if ($all_shade_items.length > not_css_visiblity_hidden) {
        $shadepicker.addClass('has-shade-overflow');
      }
    }

    $('.js-show-shade-overflow', $shadepicker).on('click', function (e) {
      e.preventDefault();
      $shadepicker.toggleClass('show-shade-overflow');
    });

    $('.js-missing-shades', $shadepicker).text(missing_skus);
  });

  Drupal.behaviors.productShadePicker = {
    observer: null,
    setupObserver: function () {
      var self = this;

      if (self.observer !== null) {
        return;
      }
      var handler = function (entries) {
        _.each(entries, function (entry) {
          if (!entry.isIntersecting) {
            return;
          }

          var elem = entry.target;

          self.attachReal(elem);
        });
      };
      var options = {
        root: null,
        rootMargin: '0px',
        threshold: [0.1]
      };

      self.observer = new IntersectionObserver(handler, options);
    },
    attach: function ($context) {
      /*
       * Lazy load the shade picker
       */
      var self = this;
      var highlightOutStockShade = false;

      this.setupObserver();

      var $shadepickers = $('.js-product-shade-picker', $context);

      $shadepickers.each(function (i, obj) {
        self.observer.observe(obj);
      });

      highlightOutStockShade = $shadepickers.hasClass('highlight-out-stock-shade');

      if (highlightOutStockShade) {
        $.each(prodcat.data.store.skus, function (index, product) {
          // Key - Out of Stock(2), Sold Out (7)
          if ([2, 7].includes(product.INVENTORY_STATUS)) {
            $(".js-product-shade[data-sku='" + product.SKU_ID + "']").addClass('cross-out-shade');
          }
        });
      }
    },

    // Route path for shade family selection.
    shadeFamilyRoute: '/sf/',

    // eslint-disable-next-line complexity
    matchShadeFamilyFromHash: function ($menuLinks) {
      var shadeFamilyRoute = this.shadeFamilyRoute;

      if (!window.location.hash || !shadeFamilyRoute || !$menuLinks || $menuLinks.length === 0) {
        return;
      }

      var hash = window.location.hash.substring(1);

      if (hash.substring(0, shadeFamilyRoute.length) !== shadeFamilyRoute) {
        return;
      }

      var parts = hash.substring(shadeFamilyRoute.length).split('/');
      var sfKey = parts[0];

      if (!sfKey) {
        return;
      }

      var matchInfo = {};
      var $match = null;

      $menuLinks.each(function (i, obj) {
        var $link = $(obj);
        var linkKey = $link.data('shade-family');

        if (linkKey === sfKey) {
          $match = $link;

          return false;
        }
      });

      if (!$match) {
        return null;
      }

      matchInfo.$matchedFamily = $match;

      // Also try to match shade if possible
      // So defaulting to sku in shade family would be
      // /sf/pink/sku/123
      if (parts.length === 3 && parts[1] === 'sku') {
        matchInfo.skuBaseId = parts[2];
      }

      return matchInfo;
    },

    attachReal: function (shadepickerEl) {
      var self = this;

      // no longer wait for onscreen
      self.observer.unobserve(shadepickerEl);

      var $shadepicker = $(shadepickerEl);
      var $product = $shadepicker.closest('.js-product');

      var invStatus = parseInt($product.attr('data-inventory-status'), 10);
      var product_shade_grayed = Drupal.settings.common.product_shade_grayed;

      if ((invStatus === 2 || invStatus === 5 || invStatus === 7) && (!product_shade_grayed)) {
        $product.parent().addClass('product-shade-disabled');
        if (site.translations && site.translations.product) {
          $product
            .parents('.product-grid__item')
            .attr('title', site.translations.product.temp_out_of_stock_msg);
        }
      }

      var $slider = $('.product-shade-picker__shades', $shadepicker);
      if ($shadepicker.parent().hasClass('product-brief__shade-picker')) {
        self.initializeSlick($slider);
      }

      if (
        !$product.data('disable-shade-carousel') &&
        $shadepicker.parent().hasClass('product-brief__shade-picker')
      ) {
        $shadepicker.addClass('slider');
      }

      $product.on('shadePicker:selectShade', function (e, skuBaseId) {
        var $active_shade = $('.js-product-shade[data-sku="SKU' + skuBaseId + '"]', $product);

        _setSmooshColor(skuBaseId, $product);
        $('select.js-sku-menu', $product).val(skuBaseId);
        $product.trigger('shadePicker:ensureActiveFamily', [$product]);

        if ($shadepicker.hasClass('slider')) {
          var $slides = $slider.find('.js-shade-picker-item');
          var viewportWidth = $(window).width();

          $slides.each(function (i, obj) {
            if ($.contains(obj, $active_shade[0])) {
              var offsetLeft = obj.offsetLeft;
              var domRect = obj.getBoundingClientRect();

              // Already in view
              if (domRect.left > 0 && domRect.right < viewportWidth) {
                return false;
              }

              var newOffset = offsetLeft - viewportWidth / 2;

              if (newOffset < 0) {
                newOffset = 0;
              }

              $slider.animate({ scrollLeft: newOffset }, 500);
            }
          });
        } else {
          $('.js-product-shade', $product).removeClass('active');
          $active_shade.addClass('active');
        }
      });

      // See if we have a matching shade family route. If so, simulate click
      // then opt out of default shade selection.
      var $menuLinks = $('.product-shade-picker__shade_family', $product);
      var matchInfo = this.matchShadeFamilyFromHash($menuLinks);

      if (matchInfo) {
        if (matchInfo.skuBaseId) {
          $product.trigger('product.skuSelect', [matchInfo.skuBaseId, true]);
        }
        matchInfo.$matchedFamily.trigger('click');

        return;
      }

      // default to current skubaseid on load
      if ($product.data('sku-base-id')) {
        var skuBaseId = $product.data('sku-base-id');
        $product.triggerHandler('shadePicker:selectShade', [skuBaseId]);
      }

      $shadepicker.trigger('shadePicker:visible-shade-change');
    },

    initializeSlick: function ($shadepicker) {
      var shadeItem = '.product-shade-picker__shade-item';
      var $defaultShadeItem = $shadepicker
        .find(
          `.js-product-shade[data-sku=SKU${$shadepicker
            .closest('.js-product')
            .attr('data-sku-base-id')}]`
        )
        .closest('.js-shade-picker-item');
      var defaultShadeIndex = $(shadeItem, $shadepicker).index($defaultShadeItem);
      var inNestedCarousel = $shadepicker.closest('.js-product-carousel').length > 0;
      var settings = {
        infinite: true,
        appendArrows: $shadepicker.closest('.product-brief__shade-picker'),
        autoplay: false,
        centerMode: true,
        centerPadding: '2px',
        focusOnSelect: true,
        lazyLoad: 'progressive',
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 7
            }
          }
        ],
        slidesToShow: 5,
        slidesToScroll: 1,
        slide: shadeItem,
        swipe: !inNestedCarousel,
        swipeToSlide: !inNestedCarousel,
        rows: 0
      };

      var isScrollable = false;

      $shadepicker.on('init', function (event, slick) {
        // initialSlide doesn't work with responsive config, so have to set it here.
        isScrollable = slick.$slides.length > slick.options.slidesToShow;

        if (isScrollable) {
          // setTimeout to fix https://github.com/kenwheeler/slick/issues/1802
          setTimeout(() => {
            $shadepicker.slick('slickGoTo', defaultShadeIndex, true);
          }, 0);
        }
        $defaultShadeItem.find('.js-product-shade').addClass('active');
      });

      // Shades which are cloned in an infinite carousel get destroyed
      // when activated and lose their classes
      $shadepicker.on('afterChange', function (event, slick, currentSlide) {
        activeShade = $shadepicker.find('.js-product-shade.active').attr('data-sku');
        $shadepicker.find(`.js-product-shade[data-sku='${activeShade}']`).addClass('active');
      });

      if (!$shadepicker.hasClass('slick-initialized')) {
        $shadepicker.slick(settings);
      }
    }
  };
})(jQuery);
